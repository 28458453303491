export const transformSphereIntoSlime = (Positions, Vector) => {
  for (let i = 0; i < Positions.count; i++) {
    Vector.fromBufferAttribute(Positions, i);

    const isLower = i > Positions.count - Positions.count / 2;
    // let amplitude = Math.abs(Vector.y) * 3 + 1;
    // amplitude = amplitude > 2 ? amplitude : 2;

    let amplitude = Math.abs(Vector.y) * 2.5 + 1;

    isLower && console.log(amplitude);

    Positions.setXYZ(
      i,
      Vector.x,
      isLower ? Vector.y / amplitude : Vector.y / 1.2,
      Vector.z
    );
  }
};
