import "./style.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";
import * as dat from "lil-gui";
import { transformSphereIntoSlime } from "./utils/Slime";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI();

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// Textures
const textureLoader = new THREE.TextureLoader();

/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight("#ffffff", 1);
scene.add(ambientLight);

const spotLight = new THREE.SpotLight(
  "#78ff00",
  0.5,
  10,
  Math.PI * 0.1,
  0.25,
  1
);
spotLight.position.set(0, 2, 3);
spotLight.target.position.x = -0.75;
scene.add(spotLight);
scene.add(spotLight.target);

/**
 * Objects
 */

/*
 * Rimuru GROUPS
 */
const rimuru = new THREE.Group();
const rimuruBody = new THREE.Group();
const rimuruEyes = new THREE.Group();

/*
 * Rimuru Interior
 */
const rimuruInteriorMaterial = new THREE.MeshStandardMaterial({
  color: "#5b9cbd",
  roughness: 1,
  opacity: 0.3,
  transparent: true,
});
const rimuruInteriorGeometry = new THREE.SphereGeometry(0.3, 32, 32);
const rimuruInteriorMesh = new THREE.Mesh(
  rimuruInteriorGeometry,
  rimuruInteriorMaterial
);

rimuruInteriorMesh.position.y = -0.04;
rimuruInteriorMesh.renderOrder = 0.9;

const rimuruInteriorGeometryPositions =
  rimuruInteriorGeometry.attributes.position;
const rimuruInteriorVector = new THREE.Vector3();

transformSphereIntoSlime(rimuruInteriorGeometryPositions, rimuruInteriorVector);

/*
 * Rimuru Exterior
 */
const rimuruExteriorMaterial = new THREE.MeshStandardMaterial({
  color: "#9edef0",
  roughness: 0.5,
  opacity: 0.8,
  transparent: true,
});
const rimuruExteriorGeometry = new THREE.SphereGeometry(0.5, 32, 32);
const rimuruExteriorMesh = new THREE.Mesh(
  rimuruExteriorGeometry,
  rimuruExteriorMaterial
);

rimuruExteriorMesh.renderOrder = 1;

const rimuruExteriorGeometryPositions =
  rimuruExteriorGeometry.attributes.position;
const rimuruExteriorVector = new THREE.Vector3();

transformSphereIntoSlime(rimuruExteriorGeometryPositions, rimuruExteriorVector);

/*
 * Rimuru Eyes
 */

/*
 * Assemble Body Mesh
 */
rimuruBody.add(rimuruExteriorMesh);
rimuruBody.add(rimuruInteriorMesh);

/*
 * Assemble Body & eyes
 */
rimuru.add(rimuruBody);
rimuru.add(rimuruEyes);

/*
 * Rimuru
 */
rimuru.position.y = 0.225;
scene.add(rimuru);

/*
 * Ground
 */
const groundTexture = textureLoader.load("/textures/ground/ground_texture.jpg");
const groundNormalTexture = textureLoader.load(
  "/textures/ground/ground_normal.png"
);
const groundRoughTexture = textureLoader.load(
  "/textures/ground/ground_rough.png"
);
const groundDisplacementTexture = textureLoader.load(
  "/textures/ground/ground_displacement.png"
);

const material = new THREE.MeshStandardMaterial({
  map: groundTexture,
  normalMap: groundNormalTexture,
  roughnessMap: groundRoughTexture,
  displacementMap: groundDisplacementTexture,
  displacementScale: 0.2,
});
material.roughness = 0.4;
material.side = THREE.DoubleSide;

const planeGeometry = new THREE.PlaneGeometry(5, 5, 200, 200);
const plane = new THREE.Mesh(planeGeometry, material);
plane.rotation.x = -Math.PI * 0.5;

scene.add(plane);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 0.1;
camera.position.z = 2;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
